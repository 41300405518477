import { InvoiceCard } from "../../../../components/InvoiceCard";
import { ModalRegisterInvoiceICTS } from "../Modal/RegisterInvoiceICTS";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { ModalShowInvoice } from "../Modal/ShowInvoice";
import { ModalRegisterInvoiceBAND } from "../Modal/RegisterInvoiceBAND";
import { companyAtom } from "../../../../store";
import { useAtom } from "jotai";
import { api } from "../../../../Services/api";
import ContentLoader from "react-content-loader";
import InfoSVG from "../../../../assets/info.svg";
import { Typography, Row, List, Col } from "@icts/web";
import { getFilteredBandInvoices } from "@/utils/getFilteredBandInvoices";
import { Container } from "./styles";
import { INfs } from "@/types/NFS";
const { Title } = Typography;

export const InvoicesList = ({
  usingICTSModalForTaxes,
  setUsingICTSModalForTaxes,
  openRegisterInvoiceModalICTS,
  setOpenRegisterInvoiceModalICTS,
}) => {
  const [company] = useAtom(companyAtom);
  const [loading, setLoading] = useState(false);
  const [invoicesList, setInvoicesList] = useState<INfs>({data: [], cnpj: '', total: 0, to: 0 , from: 0});
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const isBandEnv = import.meta.env.VITE_COMPANY_ENV === "BAND";
  const [openSeeInvoiceModal, setOpenSeeInvoiceModal] = useState(false);
  const [openRegisterInvoiceModalBand, setOpenRegisterInvoiceModalBand] = useState(false);
  const [page, setPage] = useState(1);

  const handleCallInvoicesAPI = async () => {
    setLoading(true);
   
    if(company.cnpj !== invoicesList?.cnpj){ 
      setPage(1);
    }

    const invoices = await api.getInvoicesRange(company.cnpj, page);    
    
    if(invoices?.result) {
      const { from, to, total, cnpj } = invoices;      
      const obj: INfs = {
        total,
        to,
        from,
        cnpj,
        data: [],
      };
      console.log('obj ')
      console.log(obj);
      obj.data = isBandEnv ? getFilteredBandInvoices(JSON.parse(invoices.result)) : JSON.parse(invoices.result);      
      setInvoicesList(obj);     
    } else {
      setPage(1);
      setInvoicesList({data: [], cnpj: '', total: 0, to: 0 , from: 0});
    }
    
    setLoading(false);
  }

  useEffect(() => {
    handleCallInvoicesAPI()
  }, [company,page]);
  
  return (
    <Container>
      {
        (loading) && (
          <S.InvoicesList>
            <ContentLoader
              viewBox="0 0 1580 300"
              foregroundColor="#f5f6f7"
              backgroundColor="#b1b1b1"
            >
              <rect x="0" y="0" rx="8" ry="8" width="500" height="300" />
              <rect x="540" y="0" rx="8" ry="8" width="500" height="300" />
              <rect x="1080" y="0" rx="8" ry="8" width="500" height="300" />
            </ContentLoader>
          </S.InvoicesList>
        )
      }
      {        
        (!loading && !invoicesList?.data?.length) && (
          <>
            <S.EmptyList>
              <img src={InfoSVG} alt="info icon" />
              <Title>“Você não possui nenhuma nota cadastrada ainda”</Title>
            </S.EmptyList>

            {openRegisterInvoiceModalICTS && (
              <ModalRegisterInvoiceICTS
                handleCallInvoicesAPI={handleCallInvoicesAPI}
                usingICTSModalForTaxes={usingICTSModalForTaxes}
                setUsingICTSModalForTaxes={setUsingICTSModalForTaxes}
                setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalICTS}
              />
            )}
          </>
        )
      }

      <S.InvoicesList>        
        <Row gutter={[32, 32]} justify={"space-between"}>            
          <Col span={24}>
              <List
                loading={loading}
                dataSource={invoicesList.data || []}
                disableViewSwitch={true}
                emptyText={undefined}
                view="grid"
                columns={3}
                renderItem={{        
                  grid: (invoice) => (
                    <InvoiceCard
                      key={invoice.data?.Id}
                      invoice={invoice}
                      setSelectedInvoice={setSelectedInvoice}
                      setOpenSeeInvoiceModal={setOpenSeeInvoiceModal}
                      setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalBand}
                    />
                  ),
                  list: (invoice) => (
                    <></>
                  )
                }}
                rowKey={"id"}
                pagination={{
                  onChange: (page, _) => {
                    setPage(page);
                  },
                  total: invoicesList?.total || 0,
                  to: invoicesList?.to || 0,
                  from: invoicesList?.from || 0,    
                  pageSizeOptions: [27],                  
                }}
              /> 
          </Col>
        </Row>
        

        {openRegisterInvoiceModalBand && (
          <ModalRegisterInvoiceBAND
            invoice={selectedInvoice}
            setSelectedInvoice={setSelectedInvoice}
            handleCallInvoicesAPI={handleCallInvoicesAPI}
            setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalBand}
          />
        )}

        {openSeeInvoiceModal && (
          <ModalShowInvoice
            invoiceId={selectedInvoice?.Id}
            setOpen={setOpenSeeInvoiceModal}
          />
        )}

        {openRegisterInvoiceModalICTS && (
          <ModalRegisterInvoiceICTS
            handleCallInvoicesAPI={handleCallInvoicesAPI}
            usingICTSModalForTaxes={usingICTSModalForTaxes}
            setUsingICTSModalForTaxes={setUsingICTSModalForTaxes}
            setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalICTS}
          />
        )}
      </S.InvoicesList>
    </Container>
  );
};
